$primary: #7675ed;
$white: #fff;

$lightGrey: #f5f5f5;
$purple: #6160dc;
$black: #22242c;
$blue: #54c5eb;
$red: #ff4a55;
$orange: #ffb74a;
$green: #00a389;
$grey: #8e8ea1;

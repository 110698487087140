$padding: 20px 30px;
.title {
    padding-top: 0;
    margin-bottom: 0;
}
.container {
    padding: 0;
}
.content {
    padding: $padding;
}
.rootHader {
    padding: 20px 30px;
    border-bottom: 1px solid #ededed;
}

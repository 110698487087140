@import '/src/style/theme.scss';

.subtitle {
    font-size: 16px;
    font-weight: 500;
}
.container {
    background-color: $purple !important;
    color: $white;
    padding: 0px !important;
    background-image: url('./image.svg');
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-size: cover;
    background-position-y: -9px;
    // padding: 30px !important;
}
.header {
    padding-top: 30px !important;
    border-bottom: none !important;
}
.content {
    padding-top: 0px !important;
    padding-bottom: 30px !important;
}

.link {
    text-decoration: none;
    color: $white;
}
.item {
    margin: 0 8px;
}
.bullet {
    margin-top: 16px;
}

.forgot_password_container {
    padding-top: 16px;
    font-size: 14px;
    font-weight: 500;
}
.forgot_password {
    text-decoration: none !important;
}
.input {
    margin-top: 16px;
    font-weight: 500;
}
.sign_up {
    text-decoration: none;
}
.checkbox {
}

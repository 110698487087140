.container {
    background: linear-gradient(180deg, #271f62 0%, #211d4d 100%);
    color: #fff;
}
.content {
    background-image: url('../../../../assets/images/landing/background.avif');
    background-image: url('../../../../assets/images/landing/background.webp');
    background-image: url('../../../../assets/images/landing/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 48px;
    box-sizing: border-box;
}

.logoContainer {
    display: flex;
    flex-direction: column;
}
.item {
    font-size: 14px;
    line-height: 150%;
    margin-top: 16px;
    // padding-left: 8px;
    a {
        padding-left: 8px;
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
    }
}

.avatar {
    // margin-top: -40px;
    width: 90px;
    height: 90px;
    margin-bottom: 16px;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 16px;
}

@import '/src/style/theme.scss';

.optionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    &:hover {
        background-color: $lightGrey;
    }
    .optionContent {
        display: flex;
        align-items: center;
        line-height: 1;
        .text {
            font-weight: normal;
            margin-left: 8px;
        }
    }
    .extra {
        color: $grey;
    }
    .name {
        font-size: 11px;
        color: $grey;
        line-height: 1;
        padding-left: 4px;
    }
}

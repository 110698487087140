.container {
    display: flex;
    align-items: baseline;
}
.label {
    padding: 0 6px;
}

.item {
    font-size: 12px;
    margin: 0 6px 6px 0;
}
.nativeName {
    font-size: 0.8em;
    margin-left: 4px;
}
.iconContainer {
    display: flex;
}

@import '/src/style/theme.scss';

.headerContainer {
    height: 76px;
    background-color: $white;
    box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.03);
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.logo {
    fill: $primary !important;
}

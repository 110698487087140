.languages {
    max-height: 30vh;
    overflow-y: auto;
    padding-bottom: 10px;
}
.updatePlan {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

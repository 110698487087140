@import '/src/style/theme.scss';
$height: 10px;
$radius: 40px;

.container {
    margin-bottom: 16px;
}

.chart {
    background-color: $purple;
    height: $height;
    border-radius: $radius;
    min-width: 15px;
}
.chartContainer {
    background-color: $lightGrey;
    height: $height;
    border-radius: $radius;
}
.title {
    margin-bottom: 4px;
    font-size: 12px;
}
.countContainer {
    margin-top: 8px;
    font-size: 12px;
    .count {
        color: $black;
    }
    .max {
        color: $black;
    }
}

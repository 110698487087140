.container  {
    background-color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
}
.textContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title{
    font-family: Montserrat;
    font-size: 34px;
    font-weight: 600;
    text-transform: uppercase;
}
.subtitle{
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color:#000
    
}

.image{
    width: 100%;
}
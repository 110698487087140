@import '../../style/endpoints.scss';

.popup_background {
    position: fixed;
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    height: 100%;

    background-color: rgba(75, 75, 75, 0.7);
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.popup_background_inbox {
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    @media screen and (max-width: $md) {
        height: 100%;
    }
}

@media (max-height: 750px) {
    .popup_background {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

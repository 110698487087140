.chartContainer {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.content {
    height: 100%;
    padding-top: 30px;
}
.title {
    text-align: center;
    white-space: nowrap;
}
.bottomText {
    text-align: center;
    font-size: 12px;
}
.header {
}

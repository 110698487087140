@import '/src/style/theme.scss';

.container {
    display: flex;
    justify-content: center;
}

.text {
    color: $black;
}

@import './theme.scss';

body {
    font-family: 'Roboto', sans-serif;
    background: $lightGrey;
    overflow-x: hidden;
    position: relative;
    color: #333;
}
::before,
::after {
    box-sizing: border-box;
}
* {
    box-sizing: border-box;
}

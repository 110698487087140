.container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../../../assets/images/backgrounds/Group\ 269.png');
    background-repeat: no-repeat;
    background-position: right 0px;
    background-size: auto;
    background-size: 50%;
    background-origin: border-box;
}
.title {
    font-size: 20px;
    font-weight: 500;
}
.subtitle {
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0 30px 0;
}
